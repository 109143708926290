const applications = [
	{
		id: 'wordpress',
		name: 'WordPress',
		icon: 'Urdu',
	},
	{
		id: 'wp-multisite',
		name: 'WP Multisite',
		icon: 'Indonesian',
	},
	{
		id: 'woocommerce',
		name: 'WooCommerce',
		icon: 'Korean',
	},
	{
		id: 'elementor',
		name: 'Elementor',
		icon: 'Perl',
	},
	{
		id: 'google-site-kit',
		name: 'Google Site Kit',
		icon: 'Photoshop',
	},
	{
		id: 'yoast-seo',
		name: 'Yoast SEO',
		icon: 'Spanish',
	},
	{
		id: 'wordfence',
		name: 'Wordfence',
		icon: 'Petition',
	},
	{
		id: 'litespeed-cache',
		name: 'LiteSpeed Cache',
		icon: 'Kotlin',
	},
	{
		id: 'rank-math',
		name: 'Rank Math SEO',
		icon: 'Android',
	},
	{
		id: 'wpforms',
		name: 'WPForms',
		icon: 'Fonts',
	},
	{
		id: 'classic-editor',
		name: 'Classic Editor',
		icon: 'Typo3',
	},
	{
		id: 'all-in-one',
		name: 'All in One SEO',
		icon: 'Contentful',
	},
];

export default applications;
