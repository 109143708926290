import virginia from './os/North_America/virginia';

const data = {
	title: 'Managed WordPress Hosting',
	seoDescription:
		'Fast, reliable WordPress hosting built on market-leading AWS, managed by admins with 24/7 support, best-in-class AWS security.',
	helpText:
		'AWS Public Cloud owns the fastest Time to First Byte or TTFB and fastest responses times in the industry.',
	oses: [
		{
			name: 'Default Datacenter',
			items: [virginia],
		},
	],
	stepOneTitle: 'Server location',
	stepTwoTitle: 'Pick a WordPress installation',
	stepThreeTitle: 'Choose a cloud server',
	footerSummary: '{{Size}} — {{App}} — AWS {{OS}}',
	banner: '<span class="news-label">Help? ',
	alertIcon: 'wpaws',
	alertTitle: 'WordPress Amazon EC2 virtual machines',
	alertMessage: 'WordPress tailored for Amazon Elastic Compute Cloud (EC2) with activated plugin configurations. Deployed with auto-renewing, free SSL matched to your custom domain. The server emails your WP-Admin pass, the instant Amazon assigns static IP.',
};

export default data;
