import React, { useReducer } from 'react';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import StepTitle from '../../components/StepTitle';
import { Tab, Tabs, TabList, TabPanel, TabPanels } from '../../components/Tabs';
import Control from '../../components/Control';
import { OSItem, OSItems } from '../../components/OSItems';
import { AppItems, AppItem } from '../../components/AppItems';
import { PricingTables, PricingTable } from '../../components/PricingTable';
import LaunchFooter from '../../components/LaunchFooter';
import SEO from '../../components/SEO';
import Alert from '../../components/Alert';
import { findBy, formatString } from '../../utils/forms';

// import data
import applications from '../../../form-data/wordpress/applications';
import pricings from '../../../form-data/wordpress/pricings';
import data from '../../../form-data/wordpress/index';

function getOsVersions(osId, osVersions) {
	return osVersions.map((osV, osIndex) => ({
		key: `${osId}-${osV.name}`,
		id: osIndex,
		label: osV.name,
	}));
}

const initialState = {
	osType: 0,
	os: null,
	version: null,
	application: null,
	pricing: null,
};

function getPresetApplicationForOsVersion(osType, os, version) {
	if (osType === null || os === null || version === null) {
		return null;
	}
	const { oses } = data;
	let application = null;
	if (oses[osType].items[os].versions[version].applications.length === 1) {
		application = 0;
	}
	return application;
}

function reducer(state, action) {
	const { oses } = data;
	// set OS Type from Tabs
	if (action.type === 'setOsType') {
		// don't change anything if not changing the actual osType
		if (action.payload === state.osType) {
			return state;
		}
		return {
			osType: action.payload,
			os: null,
			version: null,
			application: null,
			pricing: null,
		};
	}

	// set Active OS without version
	if (action.type === 'setOs') {
		const os = action.payload;
		// again don't change if not changing the selected OS
		if (os === state.os) {
			return state;
		}
		// if the selected OS has only one version available, then set it too
		let version = null;
		const versions = oses[state.osType].items[os].versions;
		if (versions.length === 1) {
			version = 0;
		}

		return {
			...state,
			os,
			version,
			application: getPresetApplicationForOsVersion(
				state.osType,
				os,
				version
			),
			pricing: null,
		};
	}

	// set action version
	if (action.type === 'setVersion') {
		const version = action.payload;
		// don't change if not changing the selected version
		if (version === state.version) {
			return state;
		}

		return {
			...state,
			version,
			application: getPresetApplicationForOsVersion(
				state.osType,
				state.os,
				version
			),
			pricing: null,
		};
	}

	// set active application
	if (action.type === 'setApplication') {
		const application = action.payload;
		// don't change if not changing the selected application
		if (application === state.application) {
			return state;
		}
		return {
			...state,
			application,
			pricing: null,
		};
	}

	// set active pricing
	if (action.type === 'setPricing') {
		const pricing = action.payload;
		// don't change if not changing the selected pricing
		if (pricing === state.pricing) {
			return state;
		}
		return {
			...state,
			pricing,
		};
	}

	throw new Error(`Do not understand the type ${action.type}`);
}

export default function CloudHostingWizard(props) {
	const {
		oses,
		title,
		helpText,
		stepOneTitle,
		stepTwoTitle,
		stepThreeTitle,
		footerSummary,
	} = data;

	const [state, dispatch] = useReducer(reducer, initialState);

	let selectedVersion = null;
	let osName = '';
	if (state.version !== null) {
		selectedVersion =
			oses[state.osType].items[state.os].versions[state.version];
		osName = oses[state.osType].items[state.os].name;
	}

	let selectedApplication = null;
	let appName = '';
	if (state.application !== null) {
		selectedApplication = selectedVersion.applications[state.application];
		appName = findBy(applications, 'id', selectedApplication.id).name;
	}

	let selectedPricing = null;
	let serverSize = '';
	let serverPrice = '';
	if (state.pricing !== null) {
		selectedPricing = selectedApplication.pricings[state.pricing];
		const pricing = findBy(pricings, 'id', selectedPricing.id);
		serverSize = pricing.title;
		serverPrice = pricing.price;
	}

	return (
		<Layout {...props} banner={data.banner}>
			<SEO title={title} description={data.seoDescription} />
			<Header tooltip={helpText}>{title}</Header>
			{data.alertMessage !== null && data.alertMessage !== '' ? (
				<Alert
					icon={data.alertIcon}
					message={data.alertMessage}
					title={data.alertTitle}
				/>
			) : null}
			{/** Step 1 - OSType, OS and Version */}
			<Control>
				<StepTitle step="1" withTab>
					{formatString(stepOneTitle, {
						osName,
						appName,
						serverSize,
						serverPrice,
					})}
				</StepTitle>
				<Tabs
					onChange={index => {
						dispatch({
							type: 'setOsType',
							payload: index,
						});
					}}
					index={state.osType}
				>
					<TabList>
						{oses.map(os => (
							<Tab key={os.name}>{os.name}</Tab>
						))}
					</TabList>
					<TabPanels>
						{oses.map(os => (
							<TabPanel key={os.name}>
								<OSItems>
									{os.items.map((ositem, osIndex) => {
										const osId = `${os.name}-${osIndex}`;
										return (
											<OSItem
												key={osId}
												icon={ositem.icon}
												title={ositem.name}
												active={state.os === osIndex}
												versions={getOsVersions(
													osId,
													ositem.versions
												)}
												activeVersion={state.version}
												onCoverClick={() =>
													dispatch({
														type: 'setOs',
														payload: osIndex,
													})
												}
												onVersionClick={vId => {
													dispatch({
														type: 'setVersion',
														payload: vId,
													});
												}}
											/>
										);
									})}
								</OSItems>
							</TabPanel>
						))}
					</TabPanels>
				</Tabs>
			</Control>

			{/** Step 2 - Application */}
			{selectedVersion !== null ? (
				<Control scrollOnMount>
					<StepTitle step="2">
						{formatString(stepTwoTitle, {
							osName,
							appName,
							serverSize,
							serverPrice,
						})}
					</StepTitle>
					<AppItems>
						{selectedVersion.applications.map((app, appIndex) => {
							const appId = `${state.osType}-${state.os}-${state.version}-${appIndex}`;
							const application = findBy(
								applications,
								'id',
								app.id
							);
							return (
								<AppItem
									key={appId}
									active={state.application === appIndex}
									icon={application.icon}
									title={application.name}
									onClick={() =>
										dispatch({
											type: 'setApplication',
											payload: appIndex,
										})
									}
								/>
							);
						})}
					</AppItems>
				</Control>
			) : null}

			{/** Step 3 - Pricing Table */}
			{selectedApplication !== null ? (
				<Control scrollOnMount>
					<StepTitle step="3">
						{formatString(stepThreeTitle, {
							osName,
							appName,
							serverSize,
							serverPrice,
						})}
					</StepTitle>
					<PricingTables>
						{selectedApplication.pricings.map((pTable, pIndex) => {
							const pId = `${state.osType}-${state.os}-${state.version}-${state.application}-${pIndex}`;
							const pricingTable = findBy(
								pricings,
								'id',
								pTable.id
							);
							return (
								<PricingTable
									key={pId}
									{...pricingTable}
									active={state.pricing === pIndex}
									onClick={() =>
										dispatch({
											type: 'setPricing',
											payload: pIndex,
										})
									}
								/>
							);
						})}
					</PricingTables>
				</Control>
			) : null}

			{/** Step 4 - Footer */}
			{selectedPricing !== null ? (
				<LaunchFooter
					scrollOnMount
					summary={serverPrice}
					label={formatString(footerSummary, {
						osName,
						appName,
						serverSize,
						serverPrice,
					})}
					link={selectedPricing.link}
				/>
			) : null}
		</Layout>
	);
}
