const pricing = [
	{
		id: '40gb',
		title: '40 GB SSD',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails WP-admin login after 15 min WordPress installation', 'Static Amazon IPv4', 'Up-to-date WordPress',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 1 hour after you update A Record', '40GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '2TB Premium Bandwidth', '1GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '60gb',
		title: '60 GB SSD',
		price: '$27.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails WP-admin login after 15 min WordPress installation', 'Static Amazon IPv4', 'Up-to-date WordPress',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 1 hour after you update A Record', '60GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '3TB Premium Bandwidth', '2GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '80gb',
		title: '80 GB SSD',
		price: '$39.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails WP-admin login after 15 min WordPress installation', 'Static Amazon IPv4', 'Up-to-date WordPress',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 1 hour after you update A Record', '80GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '4TB Premium Bandwidth', '4GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '160gb',
		title: '160 GB SSD',
		price: '$59.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails WP-admin login after 15 min WordPress installation', 'Static Amazon IPv4', 'Up-to-date WordPress',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 1 hour after you update A Record', '160GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '5TB Premium Bandwidth', '8GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management','phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
];

export default pricing;
