const data = {
	name: 'US East (N. Virginia)',
	icon: 'Arabic',
	versions: [
		{
			name: 'Review later in cart',
			applications: [
				{
					id: 'wordpress',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-hosting-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-hosting-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-hosting-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-hosting-160',
						},
					],
				},
				{
					id: 'woocommerce',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/woocommerce-hosting-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/woocommerce-hosting-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/woocommerce-hosting-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/woocommerce-hosting-160',
						},
					],
				},
				{
					id: 'yoast-seo',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-yoast-seo-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-yoast-seo-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-yoast-seo-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-yoast-seo-160',
						},
					],
				},
				{
					id: 'wordfence',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-wordfence-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-wordfence-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-wordfence-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-wordfence-160',
						},
					],
				},
				{
					id: 'wp-multisite',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-multisite-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-multisite-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-multisite-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-multisite-160',
						},
					],
				},
				{
					id: 'litespeed-cache',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-litespeed-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-litespeed-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-litespeed-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wordpress-litespeed-160',
						},
					],
				},
				{
					id: 'elementor',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/elementor-hosting-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/elementor-hosting-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/elementor-hosting-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/elementor-hosting-160',
						},
					],
				},
				{
					id: 'google-site-kit',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/google-site-kit-hosting-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/google-site-kit-hosting-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/google-site-kit-hosting-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/google-site-kit-hosting-160',
						},
					],
				},
				{
					id: 'rank-math',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/rank-math-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/rank-math-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/rank-math-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/rank-math-160',
						},
					],
				},
				{
					id: 'wpforms',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wpforms-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wpforms-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wpforms-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/wpforms-160',
						},
					],
				},
				{
					id: 'all-in-one',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/all-in-one-seo-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/all-in-one-seo-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/all-in-one-seo-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/all-in-one-seo-160',
						},
					],
				},
				{
					id: 'classic-editor',
					pricings: [				
						{
							id: '40gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/classic-editor-40',
						},
						{
							id: '60gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/classic-editor-60',
						},
						{
							id: '80gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/classic-editor-80',
						},
						{
							id: '160gb',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/classic-editor-160',
						},
					],
				},
			],
		},
	],
};

export default data;